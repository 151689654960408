<template>

  <div>

     <add-update
      :is-add-update-sidebar-active.sync="isAddUpdateSidebarActive"
       :data="sidebarData"
    />

    <div class="row">
      <div class="col-md-12 mt-1">
        <b-card no-body  class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Show</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="f_search"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button variant="primary" @click="addNew">
                    <span class="text-nowrap">Ajouter nouveau</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

        </div>

 

  
      <b-table
          class="position-relative"
          responsive
          :fields="tableColumns"
          :items="filteredItems"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
        >

       <!-- Column: email -->
        <template #cell(email)="data">
          
          <b-badge pill variant="light-success" class="text-capitalize" v-if="data.item.email">Active</b-badge>
          <b-badge pill variant="light-secondary" class="text-capitalize" v-else>Inactive</b-badge>
        </template>
        <!-- Column: tel -->
        <template #cell(tel)="data">
           <b-badge pill variant="light-success" class="text-capitalize" v-if="data.item.tel">Active</b-badge>
          <b-badge pill variant="light-secondary" class="text-capitalize" v-else>Inactive</b-badge>
        </template>

        <template #cell(actions)="data">
          <b-button variant="flat-info" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-info title="Modifier"  @click.stop="edit(data.item)" >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-button variant="flat-danger" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-danger title="Supprimer" @click.stop="remove(data.item.id)">
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>

     


      </b-table>

      </b-card>
    </div>
  </div>

</div>

</template>


<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
    }  from 'bootstrap-vue'

  import vSelect from 'vue-select'
  import AddUpdate from './AddUpdate.vue'
  import { mapGetters, mapActions } from "vuex"

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      ToastificationContent,
  
      AddUpdate,
    },

    data() {
      return {
          tableColumns : [
            { label: 'Abonnements', key: 'name', sortable: true },
            { label: 'Email', key: 'email', sortable: true },
            { label: 'Tel', key: 'tel', sortable: true },
            { label: 'Nombre Contact', key: 'free_contact_nbrs', sortable: true },
            { label: 'Prix', key: 'prix', sortable: true },
            
            { label: 'Actions', key: 'actions' },
          ],
          isSortDirDesc : true,
          perPage : 10,
          totalUsers : 0,
          currentPage : 1,
          perPageOptions : [10, 25, 50, 100],
          isAddUpdateSidebarActive: false,

          sidebarData: {},

          f_search:"",
        
      }
    },

    computed: {

      ...mapGetters('abonnements', {'abonnements' : 'GET_ITEMS'}),

      filteredItems() {
          let dataFilter = null

          if(this.f_search.trim() == ""){
            dataFilter = this.abonnements
          }else{
            dataFilter = this.abonnements.filter(item => {
              return item.name.trim().toLowerCase().includes(this.f_search.toLowerCase())
            })
          }
          return dataFilter
      },

    },

    methods: {
      ...mapActions('abonnements', {'fetchAbonnements' : 'fetchItems','removeItem':'removeItem'}),

      statusVariant(status)  {
        if (status === 1) return 'success'
        if (status === 0) return 'secondary'
        return ''
      },

      toggleDataSidebar(val=false) {
        this.isAddUpdateSidebarActive = val
      },

      notification(variant, title, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
            text: text,
            variant
          },
        })
      },

      addNew() {
        this.sidebarData = {}
        this.toggleDataSidebar(true)
      },

      edit(data) {
        this.sidebarData = data
        this.isAddUpdateSidebarActive = true
      },

      remove(id) {
        this.$swal({
          title: 'Voullez vous vraiment supprimé?',
          text: "Voullez vous vraiment supprimé!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.removeItem(id)
            .then(() => {
              this.notification('success',  'Supperission', 'L\'abonnement est supprimé')
            })
            .catch(() => {
              this.notification('danger',  'Supperission', 'L\'abonnement n\'est supprimé')
            })
          }
        })
      }
    },

    created() {

      this.fetchAbonnements()
    },

    watch: {

    }
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>



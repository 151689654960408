<template>
  <b-sidebar
    id="add-update-sidebar"
    :visible="isAddUpdateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-update-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
        NOUVEAU ABONNEMENT
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer ref="simpleRules">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent=""
          @reset.prevent="resetForm"
        > 

            <b-form-group label="Nom abonnement" label-for="nomAbonnement" >
              <validation-provider #default="{ errors }" name="Nom abonnement" rules="required">
                <b-form-input
                  id="nomAbonnement"
                  v-model="type_name"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nom abonnement"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            

            <b-form-group label="Nombre Contact" label-for="nbreContact" >
              <validation-provider #default="{ errors }" name="Nombre Contact" rules="required">
                <b-form-input
                  id="nbreContact"
                  type="number"
                  v-model="free_contact_nbrs"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nombre contact gratuit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Prix" label-for="prix" >
              <validation-provider #default="{ errors }" name="Prix" rules="required">
                <b-form-input
                  id="prix"
                  type="number"
                  v-model="prix"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="Prix"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


             <div class="demo-inline-spacing">
   
              <b-form-checkbox
                :checked="show_email"
                v-model="show_email"
                class="custom-control-success"
                name="show_email"
                switch
                inline
              >Email</b-form-checkbox>
            
          
              <b-form-checkbox
                :checked="show_tel"
                v-model="show_tel"
                class="custom-control-success"
                name="show_tel"
                switch
                inline
              >Téléphone</b-form-checkbox>
            </div>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" 
            type="submit"  @click="enregistrer">
              Enregistrer
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { mapActions } from "vuex"


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    BFormCheckbox,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddUpdateSidebarActive',
    event: 'update:is-add-update-sidebar-active',
  },
  props: {
    isAddUpdateSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      id: null,
      type_name: null,
      free_contact_nbrs: null,
      prix: null,
      show_email: true,
      show_tel: true,
      required
    }
  },
  methods: {
    ...mapActions('abonnements', ['addItem','updateItem']),
    enregistrer() {
      this.$refs.simpleRules.validate().then(success => {
        console.log("this.$refs.simpleRule");
        if (success) {
          if(this.id !== null && this.id >= 0) {
            console.log("update aboon show_email",this.show_email);
            console.log("update aboon show_tel",this.show_tel);
            this.updateItem({id: this.id, type_name: this.type_name, free_contact_nbrs: this.free_contact_nbrs, prix: this.prix, show_email: this.show_email, show_tel: this.show_tel}).then(() => {
              this.$emit('update:is-add-update-sidebar-active', false)
            })
          }else{
            this.addItem({type_name: this.type_name, free_contact_nbrs: this.free_contact_nbrs, prix: this.prix, show_email: this.show_email, show_tel: this.show_tel}).then(() => {
              this.$emit('update:is-add-update-sidebar-active', false)
            })
          }
         
        }
      })
    },

    resetForm() {
      this.type_name= ''
      this.free_contact_nbrs= ''
      this.prix= ''
      this.show_email= true
      this.show_tel= true
    },

    initValues(close = false) {

      if(close){


        this.type_name= null,
        this.free_contact_nbrs= null,
        this.prix= null,
        this.show_email= true,
        this.show_tel= true
        this.$refs.simpleRules.reset()
        return
      }

      if(this.data.id) return

    },
 
  },

  created()  {
    
  },
  watch: {

    isAddUpdateSidebarActive(val) {
      if(!val) return

      console.log("initt:",this.data,this.data.length);
        if(Object.entries(this.data).length === 0) {
          this.initValues(true)
        }else {
          let { id, name, free_contact_nbrs, prix, email, tel} = JSON.parse(JSON.stringify(this.data))
          this.id = id
          this.type_name= name
          this.free_contact_nbrs= free_contact_nbrs
          this.prix= prix
          this.show_email= email ? true : false
          this.show_tel= tel ? true : false
          this.initValues()
        }
    }

  }
 
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-update-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
